import React, { useState } from 'react';
import { connect } from 'react-redux';
import { signup } from '../../actions/users';
import backgroundImage from '../../assets/loginImages/login_background.png';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


function SignupForm(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [typeOfOrganization, setTypeOfOrganization] = useState('');
  const [areaOfResearch, setAreaOfResearch] = useState('');

  // Error states
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [companyError, setCompanyError] = useState(null);
  const [jobTitleError, setJobTitleError] = useState(null);
  const [typeOfOrganizationError, setTypeOfOrganizationError] = useState(null);
  const [areaOfResearchError, setAreaOfResearchError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset errors
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setCompanyError(null);
    setJobTitleError(null);
    setTypeOfOrganizationError(null);
    setAreaOfResearchError(null);

    // Check for empty fields
    if (!firstName) setFirstNameError('First name is required');
    if (!lastName) setLastNameError('Last name is required');
    if (!email) setEmailError('Email is required');
    if (!company) setCompanyError('Company is required');
    if (!jobTitle) setJobTitleError('Job title is required');
    if (!typeOfOrganization) setTypeOfOrganizationError('Type of organization is required');
    if (!areaOfResearch) setAreaOfResearchError('Area of research is required');

    // If any field is empty, stop the form submission
    if (!firstName || !lastName || !email || !company || !jobTitle || !typeOfOrganization || !areaOfResearch) {
      return;
    }

    const payload = {
      firstName,
      lastName,
      email,
      company,
      jobTitle,
      typeOfOrganization,
      areaOfResearch,
    };
    try {
      await props.signup(payload);
      Swal.fire({
        title: 'Registered!',
        text: 'Please check your email inbox.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          props.history.push('/login');
        }
      });
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  return (
    <div className="flex flex-col justify-between flex-1 antialiased">
      <div
        className="flex flex-col flex-1 object-cover"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="flex flex-1">
          <div className="w-1/3" />
          <div
            className="w-1/3"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <form onSubmit={handleSubmit} className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
              <h1 className="text-4xl font-bold text-center text-blue-500">Sign Up</h1>
              <div className="mb-4">
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                />
                {firstNameError && <p className="text-xs italic text-red-500">{firstNameError}</p>}
              </div>
              <div className="mb-4">
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                />
                {lastNameError && <p className="text-xs italic text-red-500">{lastNameError}</p>}
              </div>
              <div className="mb-4">
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                {emailError && <p className="text-xs italic text-red-500">{emailError}</p>}
              </div>
              <div className="mb-4">
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Company"
                />
                {companyError && <p className="text-xs italic text-red-500">{companyError}</p>}
              </div>
              <div className="mb-4">
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  placeholder="Job Title"
                />
                {jobTitleError && <p className="text-xs italic text-red-500">{jobTitleError}</p>}
              </div>
              <div className="mb-4">
                <select
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  value={typeOfOrganization}
                  onChange={(e) => setTypeOfOrganization(e.target.value)}
                >
                  <option value="">Select Type of Organization</option>
                  <option value="Pharma">Pharma</option>
                  <option value="Biotech">Biotech</option>
                  <option value="Agency">Agency</option>
                  <option value="Academic">Academic</option>
                  <option value="Others">Others</option>
                </select>
                {typeOfOrganizationError && <p className="text-xs italic text-red-500">{typeOfOrganizationError}</p>}
              </div>
              <div className="mb-4">
                <select
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  value={areaOfResearch}
                  onChange={(e) => setAreaOfResearch(e.target.value)}
                >
                  <option value="">Select Area of Research</option>
                  <option value="Immunology">Immunology</option>
                  <option value="Molecular Biology">Molecular Biology</option>
                  <option value="Cardiology">Cardiology</option>
                  <option value="Medical Devices">Medical Devices</option>
                  <option value="Opthalmology">Opthalmology</option>
                  <option value="Dermatology/Cosmetics">Dermatology/Cosmetics</option>
                  <option value="Oncology">Oncology</option>
                  <option value="Neurology">Neurology</option>
                  <option value="Respiratory">Respiratory</option>
                  <option value="Animal Health">Animal Health</option>
                  <option value="Agrotech">Agrotech</option>
                  <option value="Other">Other</option>
                </select>
                {areaOfResearchError && <p className="text-xs italic text-red-500">{areaOfResearchError}</p>}
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="px-4 py-2 font-bold text-white rounded bg-primary hover:bg-primary-hover focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Sign Up
                </button>
                <Link
                  to="/login"
                  className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                >
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
          <div className="w-1/3" />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  signup: (payload) => dispatch(signup(payload)),
});

export default connect(null, mapDispatchToProps)(SignupForm);