import { CREATE_ASSET_SUCCESS, GET_ASSETS_SUCCESS } from '../actionTypes/assets';

const INITIAL_STATE = {
  assets: false, // false means not fetched
  lastInsertedAsset: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_ASSET_SUCCESS:
      return {
        ...state,
        lastInsertedAsset: action.payload.data,
      };
    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.payload.data,
        lastInsertedAsset: null,
      };
    default:
      return {
        ...state,
      };
  }
}
