import { SAVE_CURRENT_CANVAS } from '../actionTypes/canvas';
import {
  CREATE_PROJECT_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_SUCCESS,
  REMOVE_PROJECT_SUCCESS,
  DUPLICATE_PROJECT_SUCCESS,
  PROJECT_ADD_SCENE_SUCCESS,
  SAVE_PROJECT_SCENE_INTO_SCENES,
  STORE_SCENES_IN_NEW_VERSION_SUCCESS,
  DELETE_PROJECT_SCENE_FROM_SCENES,
  HIGHLIGHT_PROJECT_SCENE,
  DEEMPHASIZE_PROJECT_SCENE,
  UPLOAD_IMAGE_TO_PROJECT_SCENE,
  PROJECT_SCENE_GENERATED_FROM_SCRIPT,
  CLEAN_PROJECT_SUCCESS,
  CHANGE_SELECTED_PROJECT_VERSION_SUCCESS,
  SEND_PROJECT_SUCCESS,
  TOGGLE_DESIGNER_FROM_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  DUPLICATE_PROJECT_SCENE_SUCCESS,
  MOVE_PROJECT_SCENE_LEFT,
  MOVE_PROJECT_SCENE_RIGHT,
  UNDO_SCENES_CHANGES,
  REDO_SCENES_CHANGES,
} from '../actionTypes/projects';
import {
  GENERATE_SCENE_AUDIO_SUCCESS,
  AUTOPLAY_SCENE_START,
  AUTOPLAY_SCENE_STOP,
  UPDATE_SCENE_SUCCESS,
  GENERATE_ALL_SCENE_AUDIOS_SUCCESS,
} from '../actionTypes/scenes';

const INITIAL_STATE = {
  projects: false, // false means not fetched
  lastInsertedProject: null,
  project: false,
  currentProjectScenes: false, // holds the current project scenes and will be updated each time a change is performed in the scenes collection
  hasToFetchProject: false,
  selectedSceneIndex: false,
  autoPlaySelectedScene: false, // Autoplay the AudioPanel when render
  checkedIfSceneHasChanged: false,
  selectedVersion: null,
  scenesFIFOPast: [],
  scenesFIFOFuture: [],
};

/**
 * Helper function to obtain the project's most recent updated version
 * @param {Object} project - The project to extract the version from
 */
const getMostRecentUpdatedVersionFromProject = (project) => {
  let currentVersion;
  let currentIndex;
  for (let i = 0; i < project.versions.length; i++) {
    const v = project.versions[i];
    if (!currentVersion || currentVersion.updatedAt < v.updatedAt) {
      currentVersion = v;
      currentIndex = i;
    }
  }

  return { version: currentVersion, index: currentIndex };
};

export default function (state = INITIAL_STATE, action) {
  let index;
  let scene;
  let script;
  let projectScenes;
  let currentProject;
  let designerId;
  let newProjects;
  let versionAndIndex;
  let arrayFromThePast;
  let arrayFromTheFuture;
  switch (action.type) {
    case CREATE_PROJECT_SUCCESS:
    case REMOVE_PROJECT_SUCCESS:
    case DUPLICATE_PROJECT_SUCCESS:
    case SEND_PROJECT_SUCCESS:
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        lastInsertedProject: action.payload.project,
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload.projects,
        lastInsertedProject: null,
      };
    case GET_PROJECT_SUCCESS:
      // action.payload.project.versions.forEach((vers) => {
      //   if (v === null) {
      //     v = vers;
      //   } else if (moment(vers.createdAt).diff(v.createdAt) >= 0) {
      //     v = vers;
      //   }
      // });

      // Get the most recent updated version
      versionAndIndex = getMostRecentUpdatedVersionFromProject(action.payload.project);
      currentProject = action.payload.project;
      currentProject.needSave = false;
      return {
        ...state,
        project: action.payload.project,
        currentProjectScenes: versionAndIndex.version.scenes,
        selectedVersion: versionAndIndex.index,
        hasToFetchProject: false,
        scenesFIFOPast: [versionAndIndex.version.scenes],
        scenesFIFOFuture: [],
      };
    case STORE_SCENES_IN_NEW_VERSION_SUCCESS:
    case UPLOAD_IMAGE_TO_PROJECT_SCENE:
    case UPDATE_SCENE_SUCCESS:
      return {
        ...state,
        hasToFetchProject: true,
      };
    case SAVE_CURRENT_CANVAS:
      index = action.payload.index;
      projectScenes = (state.currentProjectScenes || []).slice();
      if (projectScenes[index]) {
        projectScenes[index].willSpin = true;
      }
      return {
        ...state,
        currentProjectScenes: projectScenes,
      };
    case PROJECT_ADD_SCENE_SUCCESS:
      arrayFromThePast = state.scenesFIFOPast.slice();
      arrayFromThePast.push([
        ...state.currentProjectScenes,
        {
          thumbnail: '',
          script: '',
          action: '',
          dirty: true,
          audio: { rate: 0, language: '', duration: 0, source: '', rawScript: '' },
        },
      ]);
      return {
        ...state,
        currentProjectScenes: [
          ...state.currentProjectScenes,
          {
            thumbnail: '',
            script: '',
            action: '',
            dirty: true,
            audio: { rate: 0, language: '', duration: 0, source: '', rawScript: '' },
          },
        ],
        scenesFIFOPast: arrayFromThePast,
      };
    case DUPLICATE_PROJECT_SCENE_SUCCESS:
      index = action.payload.index;
      scene = action.payload.scene;
      projectScenes = state.currentProjectScenes.slice();
      projectScenes.splice(index + 1, 0, { ...scene, dirty: true, id: null, sceneJSONId: null });
      return {
        ...state,
        currentProjectScenes: projectScenes,
      };
    case MOVE_PROJECT_SCENE_LEFT:
      index = action.payload.index;
      scene = action.payload.scene;
      projectScenes = state.currentProjectScenes.slice();
      projectScenes.splice(index - 1, 0, { ...scene, dirty: true });
      projectScenes.splice(index + 1, 1);
      projectScenes[index] = { ...projectScenes[index], dirty: true };
      return {
        ...state,
        currentProjectScenes: projectScenes,
      };
    case MOVE_PROJECT_SCENE_RIGHT:
      index = action.payload.index;
      scene = action.payload.scene;
      projectScenes = state.currentProjectScenes.slice();
      projectScenes.splice(index + 2, 0, { ...scene, dirty: true });
      projectScenes.splice(index, 1);
      projectScenes[index] = { ...projectScenes[index], dirty: true };
      return {
        ...state,
        currentProjectScenes: projectScenes,
      };
    case PROJECT_SCENE_GENERATED_FROM_SCRIPT:
      index = action.payload.index;
      script = action.payload.script;
      projectScenes = state.currentProjectScenes.slice();
      projectScenes.splice(index + 1, 0, { thumbnail: '', script, action: '', dirty: true });
      return {
        ...state,
        currentProjectScenes: projectScenes,
      };
    case SAVE_PROJECT_SCENE_INTO_SCENES:
    case GENERATE_SCENE_AUDIO_SUCCESS:
      index = action.payload.index;
      scene = action.payload.scene;
      projectScenes = state.currentProjectScenes.slice();
      projectScenes[index] = {
        ...scene,
        dirty: action.type === SAVE_PROJECT_SCENE_INTO_SCENES,
        audio: {
          ...scene.audio,
          needsRegeneration: !!(scene.audio || {}).source && action.type === SAVE_PROJECT_SCENE_INTO_SCENES,
        },
      };
      return {
        ...state,
        currentProjectScenes: projectScenes,
      };
    case GENERATE_ALL_SCENE_AUDIOS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { indexesAndScenes } = action.payload;
      // get a copy of currentProjectScenes
      projectScenes = state.currentProjectScenes.slice();
      // Modify project scenes
      /** indexAndScenes sample object
       * { sceneIndex: number, scene: sceneObject }
       */
      if (Array.isArray(indexesAndScenes)) {
        indexesAndScenes.forEach(({ sceneIndex, scene: modifiedScene }) => {
          projectScenes[sceneIndex] = {
            ...modifiedScene,
            dirty: false,
            audio: {
              ...modifiedScene?.audio,
              needsRegeneration: false,
            },
          };
        });
      }

      return {
        ...state,
        currentProjectScenes: projectScenes,
      };
    case DELETE_PROJECT_SCENE_FROM_SCENES:
      index = action.payload.index;
      projectScenes = state.currentProjectScenes.slice();
      projectScenes.splice(index, 1);
      return {
        ...state,
        currentProjectScenes: projectScenes,
        selectedSceneIndex: false,
      };
    case HIGHLIGHT_PROJECT_SCENE:
      return {
        ...state,
        selectedSceneIndex: action.payload.index,
        checkedIfSceneHasChanged: true,
      };
    case DEEMPHASIZE_PROJECT_SCENE: {
      const { modifiedSceneIndex } = action.payload;

      arrayFromThePast = state.scenesFIFOPast.slice();
      arrayFromTheFuture = state.scenesFIFOFuture.slice();
      // push into the history if is dirty
      if (modifiedSceneIndex >= 0 && state.currentProjectScenes[modifiedSceneIndex]?.dirty) {
        arrayFromThePast.push(state.currentProjectScenes);
        arrayFromTheFuture = [];
      }
      return {
        ...state,
        selectedSceneIndex: false,
        scenesFIFOPast: arrayFromThePast,
        scenesFIFOFuture: arrayFromTheFuture,
      };
    }
    case AUTOPLAY_SCENE_START:
      return {
        ...state,
        autoPlaySelectedScene: true,
      };
    case AUTOPLAY_SCENE_STOP:
      return {
        ...state,
        autoPlaySelectedScene: false,
      };
    case CLEAN_PROJECT_SUCCESS:
      return {
        ...state,
        project: false,
        currentProjectScenes: false,
      };
    case TOGGLE_DESIGNER_FROM_PROJECT:
      currentProject = { ...action.payload.project };
      designerId = action.payload.designerId;
      if (currentProject.designers.includes(designerId)) {
        currentProject.designers.splice(designerId, 1);
      } else {
        currentProject.designers.push(designerId);
      }
      newProjects = state.projects.slice();
      newProjects[newProjects.findIndex((p) => p.id === currentProject.id)] = currentProject;
      return {
        ...state,
        projects: newProjects,
      };
    case CHANGE_SELECTED_PROJECT_VERSION_SUCCESS:
      return {
        ...state,
        selectedVersion: action.payload.selectedVersion,
        currentProjectScenes:
          state.project && state.project.versions.length > action.payload.selectedVersion
            ? state.project.versions[action.payload.selectedVersion].scenes
            : state.currentProjectScenes,
        scenesFIFOFuture: [],
        scenesFIFOPast: [
          state.project && state.project.versions.length > action.payload.selectedVersion
            ? state.project.versions[action.payload.selectedVersion].scenes
            : state.currentProjectScenes,
        ],
      };
    case UNDO_SCENES_CHANGES:
      arrayFromThePast = state.scenesFIFOPast.slice();
      arrayFromTheFuture = state.scenesFIFOFuture.slice();
      if (arrayFromThePast.length > 1) {
        projectScenes = arrayFromThePast.pop();
        arrayFromTheFuture.push(projectScenes);
      }
      return {
        ...state,
        currentProjectScenes: arrayFromThePast[arrayFromThePast.length - 1],
        scenesFIFOPast: arrayFromThePast,
        scenesFIFOFuture: arrayFromTheFuture,
      };
    case REDO_SCENES_CHANGES:
      arrayFromThePast = state.scenesFIFOPast.slice();
      arrayFromTheFuture = state.scenesFIFOFuture.slice();
      if (arrayFromTheFuture.length > 0) {
        projectScenes = arrayFromTheFuture.pop();
        arrayFromThePast.push(projectScenes);
      }
      return {
        ...state,
        currentProjectScenes: arrayFromThePast[arrayFromThePast.length - 1],
        scenesFIFOPast: arrayFromThePast,
        scenesFIFOFuture: arrayFromTheFuture,
      };
    default:
      return {
        ...state,
      };
  }
}
