// @flow
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import Loading from './components/Loading';
import configureStore from './store';
import PrivateRoute from './components/PrivateRoute';
import logo from './assets/3d_logo_loading.png';
import SignupForm from './views/Login/SignupForm';

const Login = React.lazy(() => import('./views/Login'));
const Layout = React.lazy(() => import('./containers/Layout'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword/ResetPassword')); // Importa el componente
const ChangePassword = React.lazy(() => import('./views/ChangePassword/ChangePassword')); // Importa el componente

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <React.Suspense
          fallback={<Loading onlyText hasText text={<img className="w-50 h-50" src={logo} alt="3D For Science" />} />}
        >
          <Switch>
            <Route path="/signup" component={SignupForm} />
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/reset-password"
              name="Reset Password"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/change-password/:token"
              name="Change Password"
              render={(props) => <ChangePassword {...props} />}
            />
            <PrivateRoute path="/" name="Home" render={(props) => <Layout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    </Provider>
  );
}

export default App;
