// ##### Export errors messages

// STATUS
export const HEADER_ERROR = 'Oops...';
export const NOT_FOUND_ERROR = 'The requested reource was not found';
export const UNAUTHORIZED_ERROR = "The current user doesn't have permission to perform the action";
export const TIMEOUT_ERROR = 'It was not possible to connect to the server, try again later';
export const FORBIDDEN_ERROR = "You don't have the right permissions to perform the action";
export const SERVER_ERROR = 'Something went wrong! Try again later or contact with us';
export const EXPIRED_ERROR = 'We are sorry, this resource has already expired and is not available any more';
// FORMS
export const EMAIL_NOT_VALID = 'No ha introducido un email válido';
export const CIF_NOT_VALID = 'El CIF introducido no es válido';
export const CP_NOT_VALID = 'El código postal no tiene un formato válido';
export const EMAIL_ALREADY_EXISTS = 'A user already exists with this email';
export const EXIST_ERROR = 'The resource already exists';
export const REQUIRED_FIELD = 'The field is required';
export const PAST_DATE = 'La fecha no puede ser pasada';
export const FUTURE_DATE = 'La fecha no puede ser futura';
export const INVALID_URL = 'No ha introducido una url válida';
export const PASSWORDS_NOT_MATCH = 'Las contraseñas no coinciden';
export const HIGHER_THAN_0 = 'Debe ser un número mayor o igual que 0';
// ##### Export success messages

export const HEADER_SUCCESS = 'Good job!';
export const GENERIC_SUCCESS = 'The action was completed correctly';

// Get error messages from error codes4

export function getErrorMessage(code) {
  switch (code) {
    case 'emailDuplicated':
      return EMAIL_ALREADY_EXISTS;
    default:
      return '';
  }
}
