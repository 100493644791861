import { SAVE_CURRENT_CANVAS, SET_CLIPBOARD, SET_FABRIC_CANVAS, SET_OBJECT_LAYERS } from '../actionTypes/canvas';

const INITIAL_STATE = {
  fabricCanvas: null,
  currentCanvas: false, // false means not fetched
  canvasIndex: false,
  clipboard: null,
  objectLayers: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FABRIC_CANVAS:
      return {
        ...state,
        fabricCanvas: action.payload.fabricCanvas,
      };
    case SAVE_CURRENT_CANVAS:
      return {
        ...state,
        currentCanvas: action.payload.canvas,
        canvasIndex: action.payload.index,
      };
    case SET_CLIPBOARD:
      return {
        ...state,
        clipboard: action.payload.clipboard,
      };
    case SET_OBJECT_LAYERS:
      return {
        ...state,
        objectLayers: action.payload.objectLayers,
      };
    default:
      return {
        ...state,
      };
  }
}
