import { tokenName } from '../constants/index';
import { UNAUTH_USER, AUTH_USER_LOGIN } from '../actionTypes/auth';
import axios from '../requesters/axios';
import { apiErrorHandler } from './errors';
import axiosAuth from '../requesters/axiosAuth';

export function logOutUser(done = () => {}) {
  return (dispatch) => {
    localStorage.removeItem(tokenName);
    dispatch({
      type: UNAUTH_USER,
    });
    done();
  };
}

export const loginUser = (payload, done) => (dispatch) => {
  axiosAuth
    .post(`/authenticate/`, payload)
    .then((response) => {
      const { token } = response.data;
      localStorage.setItem(tokenName, token);
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      dispatch({
        type: AUTH_USER_LOGIN,
        payload: {
          token,
          currentUser: response.data.currentUser,
        },
      });
      done(response.data);
    })
    .catch((error) => {
      apiErrorHandler(error);
      done(null, error);
    });
};

export const checkAuth = (done) => (dispatch) => {
  const token = localStorage.getItem(tokenName);
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    // get here currentUser with /me
    axios
      .get(`/currentUser/`)
      .then((response) => {
        dispatch({
          type: AUTH_USER_LOGIN,
          payload: {
            token,
            currentUser: response.data.currentUser,
          },
        });
        done(true);
      })
      .catch((error) => {
        apiErrorHandler(error);
        done(false);
      });
  } else {
    done(false);
  }
};

export const resetUserPassword = (payload, done) => (dispatch) => {
  axiosAuth
    .post(`/reset-password`, payload)
    .then((response) => {
      done(null, response.data);
    })
    .catch((error) => {
      apiErrorHandler(error);
      done(error);
    });
};

export const changeUserPassword = (payload, done) => (dispatch) => {
  axiosAuth
    .post(`/change-password`, payload)
    .then((response) => {
      done(null, response.data);
    })
    .catch((error) => {
      apiErrorHandler(error);
      done(error);
    });
};

// export const healthCheck = () => (dispatch) => {
//   axios.get(`/health-check`).then((response) => {
//     window.alert(response);
//   });
// };
