// @flow
import React from 'react';
import { BarLoader, DotLoader, BeatLoader } from 'react-spinners';
import { primary } from '../constants/colors';

type LoadingType = {|
  text: string,
  hasText: boolean,
  onlyText: boolean,
  spinnerType?: 'bar' | '',
  type: string,
  form: string,
|};

const defaultProps = {
  text: '',
  hasText: false,
  onlyText: false,
  spinnerType: '',
};

/**
 * Loading component
 */
const Loading = (props: LoadingType) => {
  const { text, hasText, onlyText, spinnerType } = props;

  function returnSpinnerByType() {
    switch (spinnerType) {
      case 'bar':
        return <BarLoader size={100} color={primary} />;
      case 'test':
        return <BeatLoader size={20} color={primary} />;
      default:
        return <DotLoader size={100} color={primary} />;
    }
  }

  return (
    <div className={`flex flex-col items-center justify-center flex-1 px-2 mb-4`}>
      {hasText && <span className="text-xl animate-bounce text-secondary">{text}</span>}
      {!onlyText && returnSpinnerByType()}
    </div>
  );
};

Loading.defaultProps = defaultProps;

export default Loading;
