import { AUTH_USER_LOGIN, UNAUTH_USER } from '../actionTypes/auth';

const INITIAL_STATE = {
  authenticated: false,
  currentUser: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER_LOGIN:
      return {
        ...state,
        authenticated: true,
        currentUser: action.payload.currentUser,
      };
    case UNAUTH_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };
    default:
      return {
        ...state,
      };
  }
}
