import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import projects from './projects';
import assets from './assets';
import canvas from './canvas';
import scenes from './scenes';
import tags from './tags';
import voiceOvers from './voiceOver';

export default (state, action) => {
  const reducers = combineReducers({
    auth,
    users,
    projects,
    assets,
    canvas,
    scenes,
    tags,
    voiceOvers,
  });
  return reducers(state, action);
};
