export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const REMOVE_PROJECT_SUCCESS = 'REMOVE_PROJECT_SUCCESS';
export const DUPLICATE_PROJECT_SUCCESS = 'DUPLICATE_PROJECT_SUCCESS';
export const PROJECT_ADD_SCENE_SUCCESS = 'PROJECT_ADD_SCENE_SUCCESS';
export const SAVE_PROJECT_SCENE_INTO_SCENES = 'SAVE_PROJECT_SCENE_INTO_SCENES';
export const STORE_SCENES_IN_NEW_VERSION_SUCCESS = 'STORE_SCENES_IN_NEW_VERSION_SUCCESS';
export const DELETE_PROJECT_SCENE_FROM_SCENES = 'DELETE_PROJECT_SCENE_FROM_SCENES';
export const HIGHLIGHT_PROJECT_SCENE = 'HIGHLIGHT_SCENE';
export const DEEMPHASIZE_PROJECT_SCENE = 'DEEMPHASIZE_PROJECT_SCENE';
export const UPLOAD_IMAGE_TO_PROJECT_SCENE = 'UPLOAD_IMAGE_TO_PROJECT_SCENE';
export const PROJECT_SCENE_GENERATED_FROM_SCRIPT = 'PROJECT_SCENE_GENERATED_FROM_SCRIPT';
export const CLEAN_PROJECT_SUCCESS = 'CLEAN_PROJECT_SUCCESS';
export const CHANGE_SELECTED_PROJECT_VERSION_SUCCESS = 'CHANGE_SELECTED_PROJECT_VERSION_SUCCESS';
export const SEND_PROJECT_SUCCESS = 'SEND_PROJECT_SUCCESS';
export const TOGGLE_DESIGNER_FROM_PROJECT = 'TOGGLE_DESIGNER_FROM_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const DUPLICATE_PROJECT_SCENE_SUCCESS = 'DUPLICATE_PROJECT_SCENE_SUCCESS';
export const MOVE_PROJECT_SCENE_RIGHT = 'MOVE_PROJECT_SCENE_RIGHT';
export const MOVE_PROJECT_SCENE_LEFT = 'MOVE_PROJECT_SCENE_LEFT';
export const UNDO_SCENES_CHANGES = 'UNDO_SCENES_CHANGES';
export const REDO_SCENES_CHANGES = 'REDO_SCENES_CHANGES';
