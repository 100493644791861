import React, { useState, useEffect } from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Loading from './Loading';
import { checkAuth, logOutUser } from '../actions/auth';
import logo from '../assets/3d_logo_loading.png';

function PrivateRoute({ render: RealComponent, ...props }) {
  const [authChecked, setAuthChecked] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    // console.log('CHECKING AUTH');
    dispatch(
      checkAuth((res) => {
        setAuthChecked(true);
        if (!res) {
          dispatch(logOutUser());
        }
        // console.log('AUTH CHECKED --> ', res);
      })
    );
  }, [dispatch]);

  return (
    <Route
      render={() => {
        return !authChecked ? (
          <Loading onlyText hasText text={<img className="w-50 h-50" src={logo} alt="3D For Science" />} />
        ) : props.authenticated ? (
          <RealComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    authenticated: state.auth.authenticated,
    currentURL: ownProps.location.pathname,
  };
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
