import axios from '../requesters/axios';
import {
  CREATE_USER_SUCCESS,
  GET_USERS_SUCCESS,
  RESET_USER_PASSWORD_SUCCESS,
  GET_DESIGNERS_SUCCESS,
  DELETE_USER_SUCCESS, USER_SIGN_UP_SUCCESS
} from '../actionTypes/users';
import { apiErrorHandler } from './errors';

export const createUser = (data, done = () => {}) => async (dispatch) => {
  try {
    const response = await axios.post(`/users/`, data);
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: {
        user: response.data,
      },
    });
    done(response.data);
  } catch (err) {
    apiErrorHandler(err);
    done(null, err);
  }
};

export const fetchUsers = (done = () => {}) => async (dispatch) => {
  try {
    const response = await axios.get(`/users/`);
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: {
        users: response.data,
      },
    });
    done(response.data);
  } catch (error) {
    apiErrorHandler(error);
    done(null, error);
  }
};

export const resetPassword = (userId, done = () => {}) => async (dispatch) => {
  try {
    const response = await axios.post(`/users/${userId}/resetPassword`);
    dispatch({
      type: RESET_USER_PASSWORD_SUCCESS,
      payload: {},
    });
    done(response.data);
  } catch (error) {
    apiErrorHandler(error);
    done(null, error);
  }
};

export const deleteUser = (userId, done = () => {}) => async (dispatch) => {
  try {
    const response = await axios.delete(`/users/${userId}`);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: {},
    });
    done(response.data);
  } catch (error) {
    apiErrorHandler(error);
    done(null, error);
  }
};

export const fetchDesigners = (done = () => {}) => async (dispatch) => {
  try {
    const response = await axios.get(`/users/designers`);
    dispatch({
      type: GET_DESIGNERS_SUCCESS,
      payload: {
        users: response.data,
      },
    });
    done(response.data);
  } catch (error) {
    apiErrorHandler(error);
    done(null, error);
  }
};

export const updateUser = (user, done) => (dispatch) => {
  if (user === null) {
    return;
  }
  axios
    .put(`/users/${user.id}`, user)
    .then((response) => {
      done(null, response.data);
    })
    .catch((error) => {
      apiErrorHandler(error);
      done(error);
    });
};

export const sendUserFeedback = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (payload === null) {
        reject();
      }
      axios
        .post(`/users/feedback`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          apiErrorHandler(error);
          reject(error);
        });
    });
  };
};


export const signup = (data, done = () => {}) => async (dispatch) => {
  try {
    const response = await axios.post(`/signup`, data);
    dispatch({
      type: USER_SIGN_UP_SUCCESS,
      payload: {
        user: response.data,
      },
    });
    done(response.data);
  } catch (err) {
    apiErrorHandler(err);
    done(null, err);
  }
};