import {
  CREATE_VOICEOVER_SUCCESS,
  GET_VOICEOVERS_SUCCESS,
  EDIT_VOICEOVER_SUCCESS,
  DELETE_VOICEOVER_SUCCESS,
} from '../actionTypes/voiceOvers';

const INITIAL_STATE = {
  voiceOvers: false, // false means not fetched
  lastInsertedVoiceOver: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_VOICEOVER_SUCCESS:
    case CREATE_VOICEOVER_SUCCESS:
      return {
        ...state,
        lastInsertedVoiceOver: action.payload.data,
      };
    case DELETE_VOICEOVER_SUCCESS:
      return {
        ...state,
        lastInsertedVoiceOver: true,
      };
    case GET_VOICEOVERS_SUCCESS:
      return {
        ...state,
        voiceOvers: action.payload.data,
        lastInsertedVoiceOver: null,
      };
    default:
      return {
        ...state,
      };
  }
}
