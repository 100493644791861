import {
  GET_SCENE_SUCCESS,
  SCENE_INITIAL_LOAD_SUCCESS,
  CHANGE_SCENE_CHECKER,
  GENERATE_SCENE_AUDIO_SUCCESS,
} from '../actionTypes/scenes';

const INITIAL_STATE = {
  scenes: false, // false means not fetched
  scene: false,
  checkedIfSceneHasChanged: false, // checks if the selected scene is another than the saved-in-redux-temporally one
  currentSceneInitialLoaded: false, // if true means the scene has been loaded from the database and the others times this scene needs to be loaded will be loaded from the redux
  currentSceneId: false, // saves the current scene id to check if another scene has benn loaded or is a re-reder of the same scene
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SCENE_SUCCESS:
    case GENERATE_SCENE_AUDIO_SUCCESS:
      return {
        ...state,
        scene: action.payload.scene,
      };
    case SCENE_INITIAL_LOAD_SUCCESS:
      return {
        ...state,
        currentSceneInitialLoaded: action.payload.status,
        currentSceneId: action.payload.currentSceneId,
        checkedIfSceneHasChanged: true,
      };
    case CHANGE_SCENE_CHECKER:
      return {
        ...state,
        checkedIfSceneHasChanged: action.payload.status,
      };
    default:
      return {
        ...state,
      };
  }
}
