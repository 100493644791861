const primary = '#1BE0EE';
const primaryHover = '#17c5d1';
const secondary = '#212725';
const tertiary = '#ff00ff';
const canvasBackground = 'rgb(26, 32, 44)';

const ALERT_COLOR = '#ed008c';
const ALERT_HOVER_COLOR = '#d90281';
const SELECTED_COLOR = '#2aa4d7';
const SELECTED_COLOR_DARK = '#0E698E';
const TRANSPARENT_SELECTED_COLOR = 'rgba(42,165,215,0.4)';
const TEXT_1_COLOR = '#363c3a';
const TEXT_2_COLOR = '#a6a6a6';
const TEXT_3_COLOR = '#eeeeee';
const BACKGROUND_COLOR = '#fafafa';
const BOX_COLOR = '#ffffff';
const BOX_STROKE_COLOR = '#eeeeee';
const BOX_STROKE_HOVER_COLOR = '#dbdbdb';
const BLACK_BOX_COLOR = '#020403';
const BLACK_BOX_STROKE_COLOR = '#363c3a';
const TOOLSMENU_LIGHT_BACKGROUND_COLOR = '#EBEBEB';
const TOOLSMENU_NORMAL_BACKGROUND_COLOR = '#B8B7B3';
const TOOLSMENU_DARK_BACKGROUND_COLOR = '#858585';
const COLOR_PICKER_TEXT_COLOR = 'rgba(229,229,229,1)';

module.exports = {
  primary,
  primaryHover,
  secondary,
  tertiary,
  canvasBackground,
  ALERT_COLOR,
  ALERT_HOVER_COLOR,
  SELECTED_COLOR,
  SELECTED_COLOR_DARK,
  TRANSPARENT_SELECTED_COLOR,
  TEXT_1_COLOR,
  TEXT_2_COLOR,
  TEXT_3_COLOR,
  BACKGROUND_COLOR,
  BOX_COLOR,
  BOX_STROKE_COLOR,
  BOX_STROKE_HOVER_COLOR,
  BLACK_BOX_COLOR,
  BLACK_BOX_STROKE_COLOR,
  TOOLSMENU_LIGHT_BACKGROUND_COLOR,
  TOOLSMENU_NORMAL_BACKGROUND_COLOR,
  TOOLSMENU_DARK_BACKGROUND_COLOR,
  COLOR_PICKER_TEXT_COLOR,
};
