const { NODE_ENV } = process.env;
const BASE_URL = process.env.REACT_APP_BASE_URL;
let baseUrl = 'http://localhost:4040';

if (BASE_URL) {
  baseUrl = `${BASE_URL}`;
} else {
  baseUrl = `http://localhost:4040`;
}

// eslint-disable-next-line no-console
console.log(NODE_ENV);
// eslint-disable-next-line no-console
console.log(baseUrl);

export const baseURL = baseUrl;
export const tokenName = 'id_token';
