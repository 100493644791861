import { CREATE_USER_SUCCESS, GET_USERS_SUCCESS, GET_DESIGNERS_SUCCESS } from '../actionTypes/users';

const INITIAL_STATE = {
  users: false, // false means not fetched
  lastInsertedUser: null,
  designers: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        lastInsertedUser: action.payload.user,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        lastInsertedUser: null,
      };
    case GET_DESIGNERS_SUCCESS:
      return {
        ...state,
        designers: action.payload.users,
      };
    default:
      return {
        ...state,
      };
  }
}
