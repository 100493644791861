import Swal from 'sweetalert2';
import {
  HEADER_ERROR,
  TIMEOUT_ERROR,
  EXPIRED_ERROR,
  UNAUTHORIZED_ERROR,
  FORBIDDEN_ERROR,
  NOT_FOUND_ERROR,
  SERVER_ERROR,
  EXIST_ERROR,
  // EMAIL_ALREADY_EXISTS,
} from '../constants/messages';

export function apiErrorHandler(error) {
  if (error.response) {
    // The request was made, but the server responded with a status code
    // that falls out of the range of 2xx
    // console.error(error.response);
    switch (error.response.status) {
      case 400:
        if (error.response.data) {
          switch (error.response.data.error) {
            default:
              return Swal.fire(HEADER_ERROR, SERVER_ERROR, 'warning');
          }
        }
        return Swal.fire(HEADER_ERROR, SERVER_ERROR, 'warning');
      case 401:
        if (error.response.data) {
          if (error.response.data.message !== 'invalid or expired jwt') {
            switch (error.response.data) {
              case 'invalidEmailOrPassword':
                return Swal.fire(HEADER_ERROR, 'User or password invalid', 'warning');
              default:
                return Swal.fire(HEADER_ERROR, UNAUTHORIZED_ERROR, 'error');
            }
          }
        } else {
          return Swal.fire(HEADER_ERROR, UNAUTHORIZED_ERROR, 'error');
        }
        return null;
      case 403:
        if (error.response.data) {
          switch (error.response.data.error) {
            default:
              return Swal.fire(HEADER_ERROR, FORBIDDEN_ERROR, 'error');
          }
        }
        return Swal.fire(HEADER_ERROR, FORBIDDEN_ERROR, 'error');
      case 404:
        return Swal.fire(HEADER_ERROR, NOT_FOUND_ERROR, 'error');
      case 408:
        return Swal.fire(HEADER_ERROR, TIMEOUT_ERROR, 'error');
      case 409:
        if (error.response.data) {
          switch (error.response.data.error) {
            case 'duplicateEmail':
              return Swal.fire(
                'Email already in use',
                'The email is already in use, please log in or try again with another one',
                'warning'
              );
            case 'duplicateDNI':
              return Swal.fire('DNI/NIE en uso', 'A user already exists with this credentials', 'warning');
            default:
              return Swal.fire(HEADER_ERROR, EXIST_ERROR, 'warning');
          }
        }
        return Swal.fire(HEADER_ERROR, EXIST_ERROR, 'warning');
      case 410:
        return Swal.fire(HEADER_ERROR, EXPIRED_ERROR, 'error');
      case 507:
        return Swal.fire('Insuffitient space', 'You have arrived at the project storage limit (16mb)', 'error');
      default:
        return Swal.fire(HEADER_ERROR, SERVER_ERROR, 'error');
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    // eslint-disable-next-line no-console
    console.error(error);
    return Swal.fire(HEADER_ERROR, SERVER_ERROR, 'error');
  }
}

export default {
  apiErrorHandler,
};
