import { GET_TAGS_SUCCESS } from '../actionTypes/tags';

const INITIAL_STATE = {
  tags: [], // false means not fetched
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
}
